import { Link } from "react-router-dom";
import logo from "./LogoHamster.png";
import "./Sidebar.css";

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="logo-container">
        <img src={logo} alt="Logo Hamster" id="logo-img"></img>
      </div>

      <ul className="menu">
        <li>
          <Link to="/">Konto</Link>
        </li>
        <li>
          <Link to="/about-us">O programie</Link>
        </li>
        <li>
          <Link to="/contact">Kontakt</Link>
        </li>
        <li>
          <Link to="/budget">Budżet</Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
