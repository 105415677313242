import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./RegistrationForm.css";

const API_URL = "https://api.joannaszczepaniak.ovh/test";

function RegistrationForm() {
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({});
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useState("");

  const onFormChange = (e, updatedAt) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const sendRegistrationForm = (data) => {
    //If you want multipart/formdata
    // const formData = new FormData();
    // formData.append("name", data.name);
    // formData.append("email", data.email);
    // formData.append("mobile", data.mobile);
    // formData.append("password", data.password);

    const formData = {
      name: data.name,
      email: data.email,
      mobile: data.mobile,
      password: data.password,
    };

    fetch(API_URL + "/user/register", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Nie udało się zarejestrować. Sprawdź dane i spróbuj ponownie"
          );
        }
        return response.json();
      })
      .then((result) => {
        setSent(true);
        setMessage("Zarejestrowano użytkownika, można się zalogować.");
      })
      .catch((error) => {
        console.error("Error:", error);
        setSent(true);
        setMessage("Wystąpił błąd : " + error.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      sendRegistrationForm(values);
    }
    setValidated(true);
  };

  return (
    <div className="registration-form-container">
      <h2>Rejestracja konta</h2>

      {!sent && (
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="registration-form"
        >
          <Form.Group as={Col} md="12" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              required
              type="email"
              placeholder="asd@asd.com"
              defaultValue=""
              onChange={onFormChange}
            />

            <Form.Control.Feedback type="invalid">
              Podaj poprawny email
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="password">
            <Form.Label>Hasło</Form.Label>
            <Form.Control
              name="password"
              required
              type="password"
              placeholder=""
              defaultValue=""
              min="6"
              max="12"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              onChange={onFormChange}
            />

            <Form.Control.Feedback type="invalid">
              Wpisz Hasło zawierające : co najmniej 1 cyfrę, 1 małą, 1 wielką
              literę, i co najmniej 1 znak specjalny. Min 6, max 12 znaków
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="name">
            <Form.Label>Imię i nazwisko</Form.Label>
            <Form.Control
              name="name"
              required
              type="text"
              placeholder="Imię i nazwisko"
              defaultValue=""
              onChange={onFormChange}
            />

            <Form.Control.Feedback type="invalid">
              Wpisz Twoje imię i nazwisko
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="12" controlId="mobile">
            <Form.Label>Nr komórki</Form.Label>
            <Form.Control
              name="mobile"
              required
              type="text"
              placeholder="+48123123123"
              defaultValue=""
              pattern="\+48\d{9}"
              onChange={onFormChange}
            />

            <Form.Control.Feedback type="invalid">
              Wpisz nr tel w formacie : +48123123123
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              name="agreement"
              required
              label="Akceptacja regulaminu"
              feedback="Należy wyrazić zgodę przed rejestracją"
              feedbackType="invalid"
              id="agreement"
              onChange={onFormChange}
            />
          </Form.Group>
          <Button type="submit">Zarejestruj się</Button>
        </Form>
      )}
      {sent && <p>{message}</p>}
    </div>
  );
}

export default RegistrationForm;
