// import logo from './logo.svg';
import ContentContainer from "./ContentContainer";
import Footer from "./Footer";
import "./MainContainer.css";
import Sidebar from "./Sidebar";

function MainContainer({ children, type = "decorated" }) {
  return (
    <div className="main-container">
      <Sidebar></Sidebar>
      <ContentContainer type={type}>{children}</ContentContainer>
      <div style={{ clear: "both" }}></div>
      <Footer></Footer>
    </div>
  );
}

export default MainContainer;
