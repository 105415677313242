import React, { useState, useEffect } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import apiClient from "../../services/apiClient";
import AddExpense from "./AddExpense";
// import InputGroup from "react-bootstrap/InputGroup";

import "./Budget.css";
import DeleteExpense from "./DeleteExpense";
import UpdateBudget from "./UpdateBudget";
import UpdateBudgetStartDay from "./UpdateBudgetStartDay";

function Budget() {
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState(0);
  const [availableAmount, setAvailableAmount] = useState("");
  const [availableAmountPerDay, setAvailableAmountPerDay] = useState("");
  const [daysToEndOfBudget, setDaysToEndOfBudget] = useState(0);
  const [expenses, setExpenses] = useState([]);

  const requestForBudget = () => {
    apiClient
      .authenticatedGet("/budget")
      .then((result) => {
        console.log(result);
        if (result) {
          setAmount(result.overriddenAmount);
          setAvailableAmount(result.availableAmount);
          setAvailableAmountPerDay(result.amountPerDay);
          setDaysToEndOfBudget(result.daysToEndOfBudget);
          setExpenses(result.expenses);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessage("Wystąpił błąd : " + error.message);
      });
  };

  useEffect(() => {
    requestForBudget();
  }, []); //No dependency to trigger in each page load

  return (
    <div className="budget-container">
      <div>
        {!message && (
          <>
            <div className="content-body-container">
              <h2>Budżet</h2>
              <Container>
                <Row>
                  <Col>
                    <span className="label">Saldo</span>
                  </Col>
                  <Col xs={6}>
                    <span className="label red">{availableAmount}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="label">Budżet</span>
                  </Col>
                  <Col xs={6}>
                    <span className="label red">{amount}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="label">Wydatki</span>
                  </Col>
                  <Col xs={6}>
                    <span className="label red">
                      {expenses
                        .map((expense) => expense.amount)
                        .reduce((a, b) => a + b, 0)}
                    </span>
                  </Col>
                </Row>
              </Container>
              <Stack gap={3} className="col-md-5 mx-auto">
                <UpdateBudget reset={requestForBudget}></UpdateBudget>
                <UpdateBudgetStartDay
                  reset={requestForBudget}
                ></UpdateBudgetStartDay>
              </Stack>
              <div className="paragraph-group">
                <p>Kwota na dzień : {availableAmountPerDay} zł</p>
                <p>Do końca zostało : {daysToEndOfBudget} dni</p>
              </div>
            </div>
            <div className="expenses-container">
              <h2>Dochód i wydatki</h2>
              <p>Dochód : {amount}</p>
              <h3>Wydatki:</h3>
              <AddExpense reset={requestForBudget} />
              <div className="expenses-list">
                {expenses.map((el) => {
                  return (
                    <p key={el.id}>
                      {el.name} {el.amount}zł{" "}
                      <DeleteExpense
                        id={el.id}
                        reset={requestForBudget}
                      ></DeleteExpense>
                    </p>
                  );
                })}
                <hr />
                Suma:{" "}
                {expenses
                  .map((expense) => expense.amount)
                  .reduce((a, b) => a + b, 0)}{" "}
                PLN
              </div>
            </div>
          </>
        )}
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}

export default Budget;
