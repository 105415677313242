import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import apiClient from "../../services/apiClient";

function DeleteAccount(properties) {
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useState("");
  const refreshParent = properties.reset;

  const handleClose = () => {
    setShow(false);
    setMessage("");
    setSent(false);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    deleteAccount();
    setValidated(true);
  };

  const deleteAccount = () => {
    apiClient
      .authenticatedDelete("/user")
      .then((result) => {
        setSent(true);
        refreshParent();
        setMessage("Konto usunięte. Można zamknąć.");
      })
      .catch((error) => {
        console.error("Error:", error);
        setSent(true);
        setMessage("Wystąpił błąd : " + error.message);
      });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Usuń konto
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Czy na pewno chcesz usunąć konto ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!sent && (
            <div>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Button type="submit">Tak, usuwam konto</Button>
              </Form>
            </div>
          )}
          {sent && <p>{message}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteAccount;
