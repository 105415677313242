import React from "react";

import "./Pages.css";

function Contact() {
  return (
    <div className="page-container">
      <h2>Kontakt</h2>

      <div>
        <h3>Niestety nie mamy supportu :(</h3>
        <p>
          Tak, aplikacja może działać niepoprawnie,a błędy w niej zamieszczone
          są celowo. Część na pewno ;)
        </p>
        <p>Możesz używać aplikacji w celach edukacyjnych.</p>
        <p>
          Jeśli chcesz użyć tej aplikacji jako trener na szkoleniu zapytaj o
          zgodę.
        </p>
        <p>Zostawiamy kontakt do admina</p>
        <p>joasia.szczepaniak AT gmail.com</p>
      </div>
    </div>
  );
}

export default Contact;
