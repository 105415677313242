import React from "react";

import "./Pages.css";

function AboutUs() {
  return (
    <div className="page-container">
      <h2>O programie</h2>

      <div>
        <p>
          Aplikacja webowa Hamster powstała w celach edukacyjnych. Zawiera
          błędy, które są pomocą do nauki testowania oprogramowania.
        </p>
        <p>
          Jednocześnie aplikacja nie jest przyjazna dla użytkownika, a poziom
          użyteczność aplikacji pozostawia wiele do życzenia.
        </p>
        <p>
          Aplikacja jest w jakiejś części napisana podczas nauki HTML5 i CSS3
          dlatego jej kod może nie być jeszcze idealny.
        </p>
        <p>Testuj! Używaj! Miłej zabawy!</p>
      </div>
    </div>
  );
}

export default AboutUs;
