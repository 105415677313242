import "./Footer.css";
function Footer() {
  return (
    <div className="main-footer">
      <hr width="100%" />
      <p>Stopka informacyjna</p>
    </div>
  );
}

export default Footer;
