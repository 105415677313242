const API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:1307";

function authHeader() {
  const token = sessionStorage.getItem("token");

  if (token) {
    return "Bearer " + token;
  } else {
    if (token == null) {
      window.location.replace("/login");
    }
  }
}

const apiClient = {
  authenticatedGet: (path) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
      redirect: "manual",
    };
    return fetch(API_URL + path, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          response.status + " " + response.statusText + " " + response.body
        );
      }
    });
  },
  authenticatedPost: (path, body) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
      body: body,
      redirect: "manual",
    };
    return fetch(API_URL + path, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          response.status + " " + response.statusText + " " + response.body
        );
      }
    });
  },
  authenticatedPatch: (path, body) => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
      body: body,
      redirect: "manual",
    };
    return fetch(API_URL + path, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          response.status + " " + response.statusText + " " + response.body
        );
      }
    });
  },
  authenticatedDelete: (path) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
      redirect: "manual",
    };
    return fetch(API_URL + path, requestOptions).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          response.status + " " + response.statusText + " " + response.body
        );
      }
    });
  },
};

export default apiClient;
