import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import "./LoginForm.css";
import { useNavigate } from "react-router-dom";
import { publish } from "../../events";

const API_URL = "https://api.joannaszczepaniak.ovh/test";

function LoginForm() {
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({});
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const onFormChange = (e, updatedAt) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const redirectToAccount = () => {
    navigate("/");
  };

  const sendLoginForm = (data) => {
    const formData = {
      email: data.email,
      password: data.password,
    };

    fetch(API_URL + "/user/login", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Nie udało się zalogować. Sprawdź dane i spróbuj ponownie"
          );
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result && result.accessToken) {
          sessionStorage.setItem("token", result.accessToken);
          sessionStorage.setItem("userId", result.user.id);
          sessionStorage.setItem("userName", result.user.name);
          publish("login");
        }

        setSent(true);
        setMessage(
          "Zalogowano użytkownika. Przekierowywanie do Twojego konta."
        );
        setTimeout(redirectToAccount, 2000);
      })
      .catch((error) => {
        console.error("Error:", error);
        setSent(true);
        setMessage("Wystąpił błąd : " + error.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      sendLoginForm(values);
    }
    setValidated(true);
  };

  return (
    <div className="registration-form-container">
      <h2>Logowanie</h2>

      {!sent && (
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="login-form"
        >
          <Form.Group as={Col} md="12" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              required
              type="email"
              placeholder="asd@asd.com"
              defaultValue=""
              onChange={onFormChange}
            />

            <Form.Control.Feedback type="invalid">
              Podaj poprawny email
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="password">
            <Form.Label>Hasło</Form.Label>
            <Form.Control
              name="password"
              required
              type="password"
              placeholder=""
              defaultValue=""
              min="6"
              max="12"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              onChange={onFormChange}
            />

            <Form.Control.Feedback type="invalid">
              Wpisz Hasło zawierające : co najmniej 1 cyfrę, 1 małą, 1 wielką
              literę, i co najmniej 1 znak specjalny. Min 6, max 12 znaków
            </Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" variant="primary">
            Zaloguj się
          </Button>
          <p>
            Nie masz konta ? <Link to="/register">Zarejestuj się</Link>
          </p>
        </Form>
      )}
      {sent && <p>{message}</p>}
    </div>
  );
}

export default LoginForm;
