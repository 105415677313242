import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import apiClient from "../../services/apiClient";

function UpdateBudget(properties) {
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({});
  const [show, setShow] = useState(false);
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useState("");
  const refreshParent = properties.reset;

  const handleClose = () => {
    setShow(false);
    setMessage("");
    setSent(false);
    setValues({});
  };
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      sendBudgetUpdate(values);
    }
    setValidated(true);
  };

  const onFormChange = (e, updatedAt) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const sendBudgetUpdate = (data) => {
    const formData = {
      overriddenAmount: parseFloat(data.amount),
    };

    apiClient
      .authenticatedPatch("/budget", JSON.stringify(formData))
      .then((result) => {
        setSent(true);
        refreshParent();
        setMessage("Zaktualizowano budżet. Można zamknąć.");
      })
      .catch((error) => {
        console.error("Error:", error);
        setSent(true);
        setMessage("Wystąpił błąd : " + error.message);
      });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Aktualizuj Budżet
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Aktualizuj Budżet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!sent && (
            <div>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group as={Col} md="12" controlId="amount">
                  <Form.Label>
                    Wpisz jaką kwotą dysponujesz na ten moment
                  </Form.Label>
                  <Form.Control
                    name="amount"
                    required
                    type="number"
                    min="0"
                    defaultValue=""
                    onChange={onFormChange}
                  />

                  <Form.Control.Feedback type="invalid">
                    Podaj poprawną kwotę
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit">Zapisz</Button>
              </Form>
            </div>
          )}
          {sent && <p>{message}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateBudget;
