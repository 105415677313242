import React from "react";
import "./ContentContainer.css";
// import logo from './logo.svg';
// import './App.css';

import Header from "./Header";

function ContentContainer({ children, type = "decorated" }) {
  return (
    <div className="content-container">
      <Header></Header>
      {type === "decorated" && (
        <>
          <div className="content-body-container">{children}</div>
          <div className="decoration-container">
            <img
              src="https://via.placeholder.com/614x324"
              width="613"
              height="324"
              alt="Decoration of Household budget"
            ></img>
          </div>
        </>
      )}
      {type === "full" && (
        <>
          <div className="content-body-container-full">{children}</div>
        </>
      )}
    </div>
  );
}

export default ContentContainer;
