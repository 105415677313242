import React from "react";

import Button from "react-bootstrap/Button";

import apiClient from "../../services/apiClient";

function DeleteExpense(properties) {
  const refreshParent = properties.reset;
  const id = properties.id;

  const deleteExpense = () => {
    apiClient
      .authenticatedDelete("/expenses/" + id)
      .then((result) => {
        refreshParent();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Button variant="danger" size="sm" onClick={deleteExpense}>
        X
      </Button>
    </>
  );
}

export default DeleteExpense;
