// import logo from './logo.svg';
import "./App.css";
import MainContainer from "./components/MainContainer";
import RegistrationForm from "./components/registration/RegistrationForm";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginForm from "./components/login/LoginForm";
import Account from "./components/account/Account";
import Budget from "./components/budget/Budget";
import AboutUs from "./components/pages/AboutUs";
import Contact from "./components/pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/register"
          element={
            <MainContainer>
              <RegistrationForm></RegistrationForm>
            </MainContainer>
          }
        />

        <Route
          path="/login"
          element={
            <MainContainer>
              <LoginForm />
            </MainContainer>
          }
        ></Route>

        <Route
          path="/about-us"
          element={
            <MainContainer>
              <AboutUs />
            </MainContainer>
          }
        ></Route>

        <Route
          path="/budget"
          element={
            <MainContainer type="full">
              <Budget />
            </MainContainer>
          }
        ></Route>

        <Route
          path="/contact"
          element={
            <MainContainer>
              <Contact />
            </MainContainer>
          }
        ></Route>

        <Route
          path="/"
          element={
            <MainContainer>
              <Account />
            </MainContainer>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
